import axios from 'axios'

import { getRequest, postRequest } from '~/infra/api/request'
// import type { User, Home } from '~/types/user'
export const UserInfo = defineStore('UserInfo', {
  state: () => ({
    userName: '',
    queryId: null,
    refId: null,
    queryMode: null,
    userInfo: {
      telegram_id: 0,
      username: '',
      tlg_age: 0,
      tlg_age_point: 0,
      tlg_rare_name: 0,
      tlg_rare_name_point: 0,
      tlg_premium: 0,
      tlg_premium_percent: 0,
      tlg_activity: 0,
      tlg_activity_point: 0,
      total_gum: 0,
      invite_url: '',
      avatar: '',
      is_first: 0
    },
    dataHome: {
      balance: 0,
      balance_text: '',
      boost: 0,
      boost_timestamp: 0,
      boost_end_timestamp: 0,
      boost_next_timestamp: 0,
      claim_timestamp: 0,
      user_claim_timestamp: 0,
      friend_boost: 0,
      mint_speed: 0,
      premium_boost: 0,
      telegram_id: 0,
      tier: '',
      tier_current: '',
      tlg_age: 0,
      username: '',
      is_auto_boost: false,
      is_auto_claim: false,
      process_earn_balance: 0,
      earned_amount: 0,
      max_earn_duration: 0,
      vip_boost: 0,
      tick_level: 0,
      wallet: null
    },
    processEarn: 0,
    valueClaim: 0,
    autoClaim: false,
    autoBoost: false,
    accessToken: '',
    routerHome: 0,
    isBoost: false,
    boosting: false,
    mintSpeed: 0,
    balance: 0,
    booster: 0,
    boostStart: 0,
    boostEnd: 0,
    boostingTime: 0,
    boostNext: 0,
    tiers: [
      {
        id: 1,
        name: 'Novice',
        price_text: '',
        tier_process: 0
      },
      {
        id: 2,
        name: 'Trader',
        price_text: '',
        tier_process: 0
      },
      {
        id: 3,
        name: 'Merchant',
        price_text: '',
        tier_process: 0
      },
      {
        id: 4,
        name: 'Shopkeeper',
        price_text: '',
        tier_process: 0
      },
      {
        id: 5,
        name: 'GUM Tycoon',
        price_text: '',
        tier_process: 0
      }
    ],
    initialSlide: 0,
    isFirtRouterHome: false,
    timeNow: 0,
    twoHours: 2 * (60 * 60),
    timeRemaining: 0,
    maxEarnDuration: 0
  }),
  getters: {},
  actions: {
    setUserInfo(data: any) {
      this.userInfo = data
    },
    async verifyRecaptcha(queryId : any) {
      try{
        const resp : any = await postRequest('/verify', {
          telegram_data: queryId,
          ref_id: this.refId
        })
        return resp?.data?.data
      }catch(e){
        console.log(e)
      }
    },
    async login(response_recaptcha : any = null) {
      const resp: any = await postRequest('/login', {
        telegram_data: this.queryId,
        ref_id: this.refId,
        mode: this.queryMode,
        g_recaptcha_response: response_recaptcha
      })
      this.accessToken = resp.data.data.access_token
      this.userInfo = resp.data.data.user
      this.routerHome = resp.data.data.claim_timestamp
      if (this.accessToken) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      }
    },
    async getDataHome() {
      const respGetMe: any = await getRequest('/home')
      if (respGetMe.data.status_code === 200) {
        const now = Date.now()
        this.dataHome = respGetMe.data.data
        this.autoBoost = this.dataHome.is_auto_boost
        this.autoClaim = this.dataHome.is_auto_claim
        this.processEarn = this.dataHome.process_earn_balance
        this.mintSpeed = Math.floor(this.dataHome.mint_speed * 100) / 100
        this.boosting = this.dataHome.boost === 130
        this.boostStart = this.dataHome.boost_timestamp
        this.boostEnd = this.dataHome.boost_end_timestamp
        this.boostNext = this.dataHome.boost_next_timestamp
        this.boostingTime = this.boostEnd - Math.floor(now / 1000)
        this.maxEarnDuration = this.dataHome.max_earn_duration
        if (this.maxEarnDuration - (Math.floor(now / 1000) - this.dataHome.user_claim_timestamp) < 0) {
          this.timeRemaining = 0
        } else {
          this.timeRemaining = this.maxEarnDuration - (Math.floor(now / 1000) - this.dataHome.user_claim_timestamp)
        }
        if (Math.floor(now / 1000) <= this.boostEnd) {
          this.mintSpeed = Math.floor(this.dataHome.mint_speed * 1.3 * 100) / 100
        }
        this.valueClaim = this.dataHome.earned_amount
        this.balance = Number(this.dataHome.balance)
        this.booster = this.dataHome.boost > 100 ? 130 : 0
      }
    },
    async postBoost() {
      const respBoost: any = await postRequest('/boost')
      if (respBoost.data.status_code === 200) {
        this.isBoost = true
        this.boosting = this.isBoost
        this.booster = 130
        this.timeNow = Math.floor(Date.now() / 1000)
        this.mintSpeed = Math.floor(this.dataHome.mint_speed * 1.3 * 100) / 100
        this.boostStart = respBoost.data?.data?.boost_timestamp
        this.boostEnd = respBoost.data?.data?.boost_end_timestamp
        this.boostNext = this.boostEnd + 1
        this.boostingTime = this.boostEnd - this.boostStart
      }
    },
    async claim() {
      try {
        const res: any = await postRequest('/claim')
        if (res.data.status_code === 200) {
          this.valueClaim = res.data.data.claim_value
          setTimeout(() => {
            this.balance = res.data.data.balance
          }, 1000)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getTier() {
      const resp: any = await getRequest('/tiers')
      if (resp.data.status_code === 200) {
        this.tiers = resp.data.data
      }
    },
    async postMint() {
      try {
        await postRequest('/mint')
      } catch (e) {
        console.log(e)
      }
    },
    setQueryData(queryParam = null, mode = null) {
      this.refId = queryParam
      this.queryMode = mode
    },
    async createWallet() {
      try {
        const resp = await postRequest('/deposit-withdraw-order/create-wallet')
        if (resp?.data?.status_code === 200) {
          this.dataHome.wallet = resp?.data.data
        }
      } catch (e) {
        console.log(e)
      }
    },
    async buyPack(payload: any) {
      try {
        const resp = await postRequest('/shop/checkout', payload)
        if (resp?.data?.status_code === 200 && !resp?.data.data.deposit) {
          ElMessage({
            message: 'Purchase successful',
            type: 'success',
            grouping: true
          })
          return true
        } 
        ElMessage({
          message: 'Transaction Cancelled',
          type: 'error',
          grouping: true
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'Transaction Cancelled',
          type: 'error',
          grouping: true
        })
      }
      return false
    },
    async buyPackWithGum(payload: any) {
      try {
        const resp = await postRequest('/shop/checkout-gum', payload)
        if (resp?.data?.status_code === 200 && !resp?.data.data.deposit) {
          ElMessage({
            message: 'Purchase successful',
            type: 'success',
            grouping: true
          })
          return true
        } 
        ElMessage({
          message: 'Transaction Cancelled',
          type: 'error',
          grouping: true
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'Transaction Cancelled',
          type: 'error',
          grouping: true
        })
      }
      return false
    },
    async buyGiftPack(payload: any) {
      try {
        const resp = await postRequest('/gift/send', payload)
        if(resp?.data.status_code === 200){
          return {
            status: true,
            message: resp?.data.message
          }
        }
        if(resp?.data.status_code === 422 || resp?.data.status_code === 400){
          return {
            status: false,
            message: resp?.data.message
          }
        }
      }catch(e){
        ElMessage({
          message: 'Transaction Cancelled',
          type: 'error',
          grouping: true
        })
        console.log(e)
      }
    }
  }
})
export default UserInfo
